<template>
  <b-form class="row" @submit.prevent="save">
    <div class="col-12">
      <h5>{{$t('changePassword')}}</h5>
      <hr>
    </div>
    <div class="form-group col-12 col-sm-4">
      <label for="password">{{$t('currentPassword')}}</label>
      <b-form-input type="password" name="password" id="password" v-model="password" v-validate="'required|min:6'" :state="validateState('password')" />
      <span class="error-inputs">{{ errors.first('password') }}</span>
    </div>
    <div class="form-group col-12 col-sm-4">
      <label for="newPassword">{{$t('newPassword')}}</label>
      <b-form-input type="password" name="newPassword" id="newPassword" v-model="newPassword" v-validate="'required|min:6'" :state="validateState('newPassword')" ref="newPassword" />
      <span class="error-inputs">{{ errors.first('newPassword') }}</span>
    </div>
    <div class="form-group col-12 col-sm-4">
      <label for="passwordConfirmation">{{$t('repeatNewPassword')}}</label>
      <b-form-input type="password" name="passwordConfirmation" id="passwordConfirmation" v-model="passwordConfirmation" v-validate="'required|min:6|confirmed:newPassword'" :state="validateState('passwordConfirmation')" />
      <span class="error-inputs">{{ errors.first('passwordConfirmation') }}</span>
    </div>
    <div class="col-12">
      <b-button type="submit" :disabled="loading" variant="primary">{{$t('save')}}</b-button>
    </div>
  </b-form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      password: '',
      newPassword: '',
      passwordConfirmation: ''
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang'
    ])
  },
  mounted () {
    document.title = `${this.$t('changePassword')} - ITFA`
  },
  methods: {
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          const payload = {
            password: this.password,
            new_password: this.newPassword,
            new_password_confirmation: this.passwordConfirmation
          }
          const console = this.$route.params.console
          const path = `auth/${this.lang}/${console}/user/change-password`
          this.$axios.post(path, payload).then((response) => {
            this.password = ''
            this.newPassword = ''
            this.passwordConfirmation = ''
            this.$toastr.success(response.data.message, window.TITLE_SUCCESS)
          })
        } else {
          this.showValidationsErrors(this.errors.items);
        }
      })
    }
  }
}
</script>
